import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-arrow", {
      'base-arrow--vertical': _ctx.vertical,
      'base-arrow--horizontal': !_ctx.vertical,
      'base-arrow--dotted': _ctx.dotted,
    }]),
    style: _normalizeStyle({
      height: _ctx.vertical ? _ctx.length : '3px',
      width: !_ctx.vertical ? _ctx.length : '3px',
    })
  }, null, 6))
}