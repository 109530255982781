import axios, { AxiosError } from 'axios';

export interface IEstimation {
  name: string;
  price: number;
  dtf: number;
}

export interface ICodeName {
  code: string;
  name: string;
}

export default abstract class API {
  private static urlBase = `${process.env.VUE_APP_SHOP_URL}/wp-json/swaghut/shop/v1/shipping/estimator`;

  /**
   * Gets shipping rates
   *
   * @param {string} cc -> Shipping country code
   * @param {number} qty -> Quantity of items sending
   * @returns {Promise} rates
   */
  public static async estimate(
    cc: string,
    qty: number
  ): Promise<IEstimation[]> {
    const response = await axios.get(`${API.urlBase}/estimate`, {
      params: { cc, qty },
    });
    return (response.data.data as { shipping_methods: IEstimation[] })
      .shipping_methods;
  }

  public static getCountries(): Promise<ICodeName[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.urlBase}/countries`)
        .then((response) => {
          if (response.data.success)
            resolve(
              (response.data.data as { countries: ICodeName[] }).countries
            );
          else reject(response.data.message);
        })
        .catch((error: AxiosError) => reject(error.message));
    });
  }
}
