
import { defineComponent } from 'vue';

// TYPES
import { ICarrierRate } from '@/services/api.interfaces';

// CONSTANTS
import { RATE_IDS } from '@/services/api.constants';

// UTILS
import { formatCurrency } from '@/utils/currency';

export default defineComponent({
  name: 'CarrierCard',
  props: {
    rate: {
      type: Object as () => ICarrierRate,
      required: true,
    },
  },
  data() {
    return {
      formatCurrency: formatCurrency,
    };
  },
  methods: {
    getDeliveryTime(): string {
      // Domestic
      if (this.rate.id === RATE_IDS.DOMESTIC_GLS_STANDARD) {
        return this.$t('carrierCards_delivery_time_gls_standard').toString();
      }
      if (this.rate.id === RATE_IDS.DOMESTIC_DHL_EXPRESS) {
        return this.$t('carrierCards_delivery_time_dhl_express').toString();
      }

      // Zonos - we check if the rate id contains the word standard or express
      const key = this.rate.id.toUpperCase();
      if (key.includes('ECONOMY') || key.includes('STANDARD')) {
        return this.$t('carrierCards_delivery_time_zonos_standard').toString();
      }
      if (key.includes('EXPRESS')) {
        return this.$t('carrierCards_delivery_time_zonos_express').toString();
      }

      return '';
    },
  },
});
