import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31fb92e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_slider = _resolveComponent("a-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_slider, {
      marks: _ctx.mark,
      "tooltip-visible": _ctx.tooltipVisible,
      min: _ctx.min,
      max: _ctx.max,
      disabled: _ctx.disabled,
      onChange: _ctx.onChange
    }, null, 8, ["marks", "tooltip-visible", "min", "max", "disabled", "onChange"])
  ]))
}