import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b74423a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carrier-card" }
const _hoisted_2 = { class: "carrier-card__name" }
const _hoisted_3 = { class: "carrier-card__total" }
const _hoisted_4 = { class: "carrier-card__fees" }
const _hoisted_5 = { class: "carrier-card__delivery-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.rate.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formatCurrency(_ctx.rate.total, _ctx.$i18n.locale, 'EUR')), 1)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(`${_ctx.$t('carrierCards_fees_sub_text')} ${_ctx.formatCurrency(
          _ctx.rate.fees,
          _ctx.$i18n.locale,
          'EUR'
        )}`), 1),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getDeliveryTime()), 1)
  ]))
}