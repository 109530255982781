import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './v2/AppV2.vue';

/* INSTALLED PLUGINS */
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

/* FROM /PLUGINS FOLDER */
import i18n from './plugins/i18n';

/* CSS  */
import './assets/ant-variables.less';
import './assets/fonts.scss';
import './assets/global.scss';

const pinia = createPinia();
const app = createApp(App);
app.use(Antd).use(i18n).use(pinia).mount('#app');
