export default {
  title: 'Shipping Price Estimator',

  /* ExamplePacks */
  examplePacks_pack_name: 'Sample: Sales Outreach Pack',
  examplePacks_pack_more_info: 'Whats in the pack?',
  examplePacks_pack_value_text: 'Commerical Value:',
  examplePacks_pack_weight_text: 'Volumetric Weight:',

  /* ShippingDetails */
  shippingDetails_no_destination: 'Please select a destination',

  /* ShippingDetailsQuantity*/
  shippingDetailsQuantity_title: 'Pack Quantity',

  /* ShippingDetailsLocation */
  shippingDetailsLocation_title: 'Shipping Location',
  shippingDetailsLocation_destination_placeholder: 'Select destination',

  /* ShippingDetailsTotals */
  shippingDetailsTotals_title: 'Shipping Prices',
  shippingDetailsTotals_calculate_button: 'Calculate Price',

  /* CarrierCards */
  carrierCards_fees_sub_text: 'taxes / duties / fees',
  carrierCards_delivery_time_gls_standard: 'Average transit time: 3-7 days',
  carrierCards_delivery_time_dhl_express: 'Average transit time: 1-3 days',
  carrierCards_delivery_time_zonos_standard: 'Average transit time: 4-7 days',
  carrierCards_delivery_time_zonos_express: 'Average transit time: 1-3 days',
};
