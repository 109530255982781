
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BaseSlider',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    tooltipVisible: {
      type: Boolean,
      default: false,
    },
    mark: {
      type: Object as PropType<Record<number, any>>,
      required: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Emits the selected value
     *
     * @param {number} value
     * @returns {void}
     */
    onChange(value: number): void {
      this.$emit('update:modelValue', value);
      this.$emit('change', value);
    },
  },
});
