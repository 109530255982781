import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import API, { ICodeName, IEstimation } from '@/v2/api';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { SelectProps } from 'ant-design-vue';
import { ICarrierRate } from '@/services/api.interfaces';

export const useMyStore = defineStore('estimator', () => {
  const loading = ref(0);
  const state = reactive({
    countries: [] as SelectProps['options'],
    carriersRates: [] as ICarrierRate[],
    errorMessage: '',
    getting: false,
  });

  let quantity = 1;
  let country: null | string = null;

  function changeCountry(cc: string) {
    country = cc;
    getShippingRates();
  }

  function changeQuantity(qty: number) {
    quantity = qty;
    if (null !== country) getShippingRates();
  }

  function getShippingRates() {
    loading.value++;
    state.getting = true;
    API.estimate(country as string, quantity)
      .then((response) => {
        state.carriersRates = response.map((r: IEstimation) => ({
          name: r.name,
          fees: r.dtf,
          total: r.price,
          id: r.name,
        }));
      })
      .finally(() => {
        loading.value--;
        state.getting = false;
      });
  }

  function loadCountries() {
    loading.value++;
    API.getCountries()
      .then((countries: ICodeName[]) => {
        state.countries = countries.map((c: ICodeName) => ({
          key: c.name,
          value: c.code,
          label: `${getUnicodeFlagIcon(c.code)} ${c.name}`,
        }));
      })
      .catch(
        (error) =>
          (state.errorMessage =
            error instanceof Error ? error.message : 'Error')
      )
      .finally(() => loading.value--);
  }

  return { loading, state, changeCountry, changeQuantity, loadCountries };
});
