
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExamplePacks',
  data() {
    return {
      selctedPack: process.env.VUE_APP_PACK_ID,
    };
  },
});
