/**
 * Formats the passed in number
 * to a currency
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param {number} total
 * @param {string} locale
 * @param {string} currency
 * @returns {string} -> currency
 */
function formatCurrency(
  total: number,
  locale: string,
  currency: string
): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(total);
}

export { formatCurrency };
